import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("D:/dev_web/layouts/default.vue").then(m => m.default || m)),
  empty: defineAsyncComponent(() => import("D:/dev_web/layouts/empty.vue").then(m => m.default || m)),
  layout0: defineAsyncComponent(() => import("D:/dev_web/layouts/layout0.vue").then(m => m.default || m)),
  layout1: defineAsyncComponent(() => import("D:/dev_web/layouts/layout1.vue").then(m => m.default || m)),
  layout2: defineAsyncComponent(() => import("D:/dev_web/layouts/layout2.vue").then(m => m.default || m)),
  layout3: defineAsyncComponent(() => import("D:/dev_web/layouts/layout3.vue").then(m => m.default || m)),
  layout4: defineAsyncComponent(() => import("D:/dev_web/layouts/layout4.vue").then(m => m.default || m)),
  layout5: defineAsyncComponent(() => import("D:/dev_web/layouts/layout5.vue").then(m => m.default || m)),
  singlepage: defineAsyncComponent(() => import("D:/dev_web/layouts/singlepage.vue").then(m => m.default || m))
}