import unhead_CdjsNwsqar from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oKZ4w3O5Iu from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9IlI3jxVBW from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HRi54aSYOa from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_e7wZQrrM6U from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HhFnsX7HZ7 from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "D:/dev_web/.nuxt/components.plugin.mjs";
import prefetch_client_ACM99BB4q8 from "D:/dev_web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_tk6ngj3otcuskvi5lro5wqqivu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_dpfiFqyzZJ from "D:/dev_web/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_4B36TCRBE1 from "D:/dev_web/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5qh6kontAo from "D:/dev_web/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_uFFtelOTRt from "D:/dev_web/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.6.2_vite@6.1.0_@ty_p2h25eqnprdjhbiuvy2c3nsvuq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_AM0heijCUw from "D:/dev_web/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.6.2_vite@6.1.0_@ty_p2h25eqnprdjhbiuvy2c3nsvuq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_1m5gbfeAD5 from "D:/dev_web/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.6.2_vite@6.1.0_@ty_p2h25eqnprdjhbiuvy2c3nsvuq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_fxr5QpR3De from "D:/dev_web/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_2IIyvbwUxS from "D:/dev_web/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_terser@5.39.0_yaml_ujlyylxrq464f3mhgebmyxaeem/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import vue_date_picker_AkrrI4qPP9 from "D:/dev_web/plugins/vue-date-picker.ts";
export default [
  unhead_CdjsNwsqar,
  router_oKZ4w3O5Iu,
  payload_client_9IlI3jxVBW,
  navigation_repaint_client_HRi54aSYOa,
  check_outdated_build_client_e7wZQrrM6U,
  chunk_reload_client_HhFnsX7HZ7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ACM99BB4q8,
  plugin_client_dpfiFqyzZJ,
  switch_locale_path_ssr_4B36TCRBE1,
  i18n_5qh6kontAo,
  slideovers_uFFtelOTRt,
  modals_AM0heijCUw,
  colors_1m5gbfeAD5,
  plugin_client_fxr5QpR3De,
  plugin_2IIyvbwUxS,
  vue_date_picker_AkrrI4qPP9
]